<script setup lang="ts">
const props = defineProps<{
  clientId: string;
  html: string;
}>();

const regex = /\[([^\]]+)\]/g;

const snippets = computed(() => {
  if (!props.html) return [];
  const matches = (props.html || "").match(regex);
  if (!matches) return [];
  return matches.map((match) => match.slice(1, -1));
});

const { data } = useFetch("/api/snippets", {
  query: {
    name: snippets.value.join(","),
    clientId: props.clientId,
  },
});

const finalHtml = computed(() => {
  if (!props.html) return "";
  if (!data.value) return props.html;
  let HTML = props.html;
  snippets.value.forEach((snippet) => {
    const snippetData = (data.value || []).find((s) => s.name === snippet);
    if (!snippetData) return;
    HTML = HTML.replace(`<strong>[${snippet}]</strong>`, snippetData.body);
  });
  return HTML;
});
</script>
<template>
  <div class="prose" v-html="finalHtml"></div>
</template>
